import React, { useState, useEffect } from "react"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

function PrivacyPolicy() {
  const [id, setId] = useState("privacy-policy")

  useEffect(() => {
    if (detectMob()) {
      setId("m-privacy-policy")
    }
  }, [])
  return (
    <section id={id} className="section-150">
      <SEO title="Privacy Policy" />
      <div className="container">
        <div style={{ marginBottom: 50 }} className="text-center">
          <div className="title">Privacy Policy</div>
        </div>

        <div className="mb-5">
          <div>
            <ol style={{ fontWeight: "bold" }}>
              <li>Introduction</li>
            </ol>
            <p style={{ marginLeft: "0.4in" }}>
              Welcome to PT. Eksekutif Media Utama.
            </p>
            <p style={{ marginLeft: "0.4in" }}>
              PT. Eksekutif Media Utama (“us”, “we”, or “our”) operates
              https://www.executivemafia.com/ (hereinafter referred to as
              “Service”).
              <br />
              <br />
              Our Privacy Policy governs your visit to
              https://www.executivemafia.com/, and explains how we collect,
              safeguard and disclose information that results from your use of
              our Service.
              <br />
              <br />
              We use your data to provide and improve Service. By using Service,
              you agree to the collection and use of information in accordance
              with this policy. Unless otherwise defined in this Privacy Policy,
              the terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions.
              <br />
              <br />
              Our Terms and Conditions (“Terms”) govern all use of our Service
              and together with the Privacy Policy constitutes your agreement
              with us (“agreement”).
            </p>
            <ol style={{ fontWeight: "bold" }} start="2">
              <li>Definitions</li>
            </ol>
            <p style={{ marginLeft: "0.4in" }}>
              SERVICE means the https://www.executivemafia.com/ website operated
              by PT. Eksekutif Media Utama.
              <br />
              <br />
              PERSONAL DATA means data about a living individual who can be
              identified from those data (or from those and other information
              either in our possession or likely to come into our possession).
              <br />
              <br />
              USAGE DATA is data collected automatically either generated by the
              use of Service or from Service infrastructure itself (for example,
              the duration of a page visit).
              <br />
              <br />
              COOKIES are small files stored on your device (computer or mobile
              device).
              <br />
              <br />
              DATA CONTROLLER means a natural or legal person who (either alone
              or jointly or in common with other persons) determines the
              purposes for which and the manner in which any personal data are,
              or are to be, processed. For the purpose of this Privacy Policy,
              we are a Data Controller of your data.
              <br />
              <br />
              DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural or legal
              person who processes the data on behalf of the Data Controller. We
              may use the services of various Service Providers in order to
              process your data more effectively.
              <br />
              <br />
              DATA SUBJECT is any living individual who is the subject of
              Personal Data.
              <br />
              <br />
              THE USER is the individual using our Service. The User corresponds
              to the Data Subject, who is the subject of Personal Data.
            </p>
            <ol style={{ fontWeight: "bold" }} start="3">
              <li>Information Collection and Use</li>
            </ol>
            <p style={{ marginLeft: "0.4in" }}>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
            <ol style={{ fontWeight: "bold" }} start="4">
              <li>Types of Data Collected</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              <b>4.1. Personal Data</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </p>
            <ol type="1" style={{ marginLeft: "0.6in" }}>
              <li> Email address</li>
              <li> First name and last name</li>
              <li> Phone number</li>
              <li> Telegram Username</li>
              <li> Instagram Handle</li>
              <li> Address, Country, State, Province, ZIP/Postal code, City</li>
              <li> Cookies and Usage Data</li>
            </ol>
            <p style={{ marginLeft: "0.75in" }}>
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>4.2. Usage Data</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              We may also collect information that your browser sends whenever
              you visit our Service or when you access Service by or through any
              device (“Usage Data”).
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              When you access our Service with a device, this Usage Data may
              include information such as your computer’s Internet Protocol
              address (e.g. IP address), browser type, browser version, the
              pages of our Service that you visit, the time and date of your
              visit, the time spent on those pages, network connection type (for
              example, WIFI, 3G, LTE), unique device identifiers and other
              diagnostic data.{" "}
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>4.3. Location Data</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              We may use and store information about your location if you give
              us permission to do so (“Location Data”). We use this data to
              provide features of our Service, to improve and customize our
              Service.
              <br />
              <br />
              You can enable or disable location services when you use our
              Service at any time by way of your device settings.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>4.4. Tracking Cookies Data</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
              <br />
              <br />
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
              <br />
              <br />
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
              <br />
              <br />
              Examples of Cookies we use:
            </p>
            <ol type="1" style={{ marginLeft: "0.6in" }}>
              <li>
                Session Cookies: We use Session Cookies to operate our Service.
              </li>
              <li>
                Preference Cookies: We use Preference Cookies to remember your
                preferences and various settings.
              </li>
              <li>
                Security Cookies: We use Security Cookies for security purposes.
              </li>
              <li>
                Advertising Cookies: Advertising Cookies are used to serve you
                with advertisements that may be relevant to you and your
                interests.
              </li>
            </ol>
            <ol style={{ fontWeight: "bold" }} start="5">
              <li>Use of Data</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              PT. Eksekutif Media Utama uses the collected data for various
              purposes:
            </p>
            <ol type="1" style={{ marginLeft: "0.6in" }}>
              <li>To provide and maintain our Service;</li>
              <li>To notify you about changes to our Service;</li>
              <li>
                To allow you to participate in interactive features of our
                Service when you choose to do so;
              </li>
              <li>To provide customer support;</li>
              <li>
                To gather analysis or valuable information so that we can
                improve our Service;
              </li>
              <li>To monitor the usage of our Service;</li>
              <li>To detect, prevent and address technical issues;</li>
              <li>To fulfil any other purpose for which you provide it;</li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li>
                To provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li>
                To provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information;
              </li>
              <li>
                In any other way we may describe when you provide the
                information;
              </li>
              <li>For any other purpose with your consent.</li>
            </ol>
            <ol style={{ fontWeight: "bold" }} start="6">
              <li>Retention of Data</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
              <br />
              <br />
              We will also retain Usage Data for internal analysis purposes.
              Usage Data is generally retained for a shorter period, except when
              this data is used to strengthen the security or to improve the
              functionality of our Service, or we are legally obligated to
              retain this data for longer time periods.
            </p>
            <ol style={{ fontWeight: "bold" }} start="7">
              <li>Transfer of Data</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              <br />
              <br />
              If you are located outside Indonesia and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to Indonesia and process it there.
              <br />
              <br />
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
              <br />
              <br />
              PT. Eksekutif Media Utama will take all the steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organization or a country
              unless there are adequate controls in place including the security
              of your data and other personal information.
            </p>
            <ol style={{ fontWeight: "bold" }} start="8">
              <li>Disclosure of Data</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We may disclose personal information that we collect, or you
              provide:
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>8.1. Disclosure for Law Enforcement.</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              Under certain circumstances, we may be required to disclose your
              Personal Data if required to do so by law or in response to valid
              requests by public authorities.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>8.2. Business Transaction.</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              If we or our subsidiaries are involved in a merger, acquisition or
              asset sale, your Personal Data may be transferred.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>8.3. Other cases, We may disclose your information also:</b>
            </p>
            <ol type="1" style={{ marginLeft: "0.6in" }}>
              <li>to our subsidiaries and affiliates;</li>
              <li>
                to contractors, service providers, and other third parties we
                use to support our business;
              </li>
              <li>to fulfill the purpose for which you provide it;</li>
              <li>
                for the purpose of including your company’s logo on our website;
              </li>
              <li>
                for any other purpose disclosed by us when you provide the
                information;
              </li>
              <li>with your consent in any other cases;</li>
              <li>
                if we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of the Company, our customers,
                or others.
              </li>
            </ol>
            <ol style={{ fontWeight: "bold" }} start="9">
              <li>Service Providers</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), provide Service on our behalf,
              perform Service-related services or assist us in analyzing how our
              Service is used.
              <br />
              <br />
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
            <ol style={{ fontWeight: "bold" }} start="10">
              <li>Technical Service Partners</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We work with technical service partners who provide specific data
              to us in order to ensure better deliverability of services. So
              that we can provide our service, content and features.
            </p>
            <ol style={{ fontWeight: "bold" }} start="11">
              <li>Social Media</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We use Instagram and Telegram as our third-party application to
              provide the network platform to communicate and interact within
              users as well as our admins and our bots service generator.
            </p>
            <ol style={{ fontWeight: "bold" }} start="12">
              <li>Links to Other Sites</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
              <br />
              <br />
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
            <ol style={{ fontWeight: "bold" }} start="13">
              <li>Third Party Analytics and Advertising</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>13.1. Google Analytics</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              This website uses functions of the web analysis service Google
              Analytics. Google Analytics uses so-called cookies. Cookies are
              text files, which are stored on your computer and that enable an
              analysis of the use of the website by users. The information
              generated by cookies on your use of this website is usually
              transferred to a Google server in the United States, where it is
              stored. The operator of this website has a legitimate interest in
              the analysis of user patterns to optimize both, the services
              offered online and the operator’s advertising activities.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              <b>13.2. Google Advertising</b>
            </p>
            <p style={{ marginLeft: "0.75in" }}>
              This website uses functions of Google Ads in order to reach
              existing and potential customers and audiences for advertising
              purposes.
            </p>
            <ol style={{ fontWeight: "bold" }} start="14">
              <li>Authentication Partner</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              If you register or log in to use our Service using third-party
              credentials (such as Google), we will collect your information
              from this third-party to help you create an account with us.
            </p>
            <ol style={{ fontWeight: "bold" }} start="15">
              <li>Invisible reCAPTCHA Policy</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We use the Invisible reCAPTCHA. By that, you are subject to
              Google’s Terms of Service and Privacy Policy.
              <br />
              <br />
              By collecting personal information about users to determine
              whether you are humans and not spam bots. reCAPTCHA checks to see
              if the computer or mobile device has a Google cookie placed on it.
              A reCAPTCHA-specific cookie gets placed on the user’s browser and
              a complete snapshot of the user’s browser window is captured.
              <br />
              <br />
              Browser and user information collected includes: All cookies
              placed by Google in the last 6 months CSS information The
              language/date Installed plug-ins All Javascript objects.
            </p>
            <ol style={{ fontWeight: "bold" }} start="16">
              <li>Data Security</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Our website is scanned on a regular basis for security holes and
              known vulnerabilities in order to make your visit to our site as
              safe as possible. Your personal information is contained behind
              secured networks and is only accessible by a limited number of
              persons who have special access rights to such systems, and are
              required to keep the information confidential. In addition, all
              sensitive/credit information you supply is encrypted via Secure
              Socket Layer (SSL) technology.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              We implement a variety of security measures when a user places an
              order enters, submits, or accesses their information to maintain
              the safety of your personal information.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              All transactions are processed through a gateway provider and are
              not stored or processed on our servers.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              All Executive Mafia staff have a duty to respect User
              confidentiality. A confidentiality obligation is included in every
              employment contract of Executive Mafia, employees who have access
              to the personal data, as well as in those with its subcontractors.
            </p>
            <ol style={{ fontWeight: "bold" }} start="17">
              <li>
                Rights of Opposition, Access, Rectification and Retraction
              </li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Users may also, at any time, access their data in order to modify
              or fix their personal data totally or partially, by logging into
              their accounts and update at profile setting.
            </p>
            <ol style={{ fontWeight: "bold" }} start="18">
              <li>Applicable Law</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              The current privacy policy is part of the General Terms and
              Conditions of the Executive Mafia Site, and as such, it will be
              governed by and construed in accordance with the laws of
              Indonesia, without regard to conflict of laws principles.
            </p>
            <ol style={{ fontWeight: "bold" }} start="19">
              <li>Payment Methods</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              We may provide paid products and/or services within Service. In
              that case, we use third-party services for payment processing.
              <br />
              <br />
              We will not store or collect your payment card details. That
              information is provided directly to our third-party payment
              processors whose use of your personal information is governed by
              their Privacy Policy.
            </p>
            <ol type="1" start="1" style={{ marginLeft: "0.5in" }}>
              <li>Bank Account</li>
            </ol>
            <p style={{ marginLeft: "0.7in" }}>
              We are using Bank Account, only for National-Wide (Indonesia)
            </p>
            <ol type="1" start="2" style={{ marginLeft: "0.5in" }}>
              <li>PayPal</li>
            </ol>
            <p style={{ marginLeft: "0.7in" }}>
              We are using PayPal transaction. If you choose to pay with an
              invoice, you will be given a payment details about our products.
              We may receive data from our payment partners so we will send your
              invoice and process your payment.
            </p>
            <ol type="1" start="3" style={{ marginLeft: "0.5in" }}>
              <li>Apple In-app-purchase</li>
            </ol>
            <p style={{ marginLeft: "0.7in" }}>
              We are using Apple In-App-Purchase for every purchase made from
              Apple devices. We may receive payment details about your purchases
              so we can process your payment.
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              If you choose to subscribe from our website, you will be directed
              directly to third party payment processor site as soon as you
              click the subscribe button.
            </p>
            <ol style={{ fontWeight: "bold" }} start="20">
              <li>Usage by Minors</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Our Services are not intended for use by children under the age of
              13 (“Child” or “Children”).
              <br />
              <br />
              We do not knowingly collect personally identifiable information
              from Children under 13. If you become aware that a Child has
              provided us with Personal Data, please contact us. If we become
              aware that we have collected Personal Data from Children without
              verification of parental consent, we take steps to remove that
              information from our servers.
              <br />
              <br />
              To review, update, or delete your child’s contact information,
              please contact us. You may also send your request to the postal
              address listed below. To protect your child’s safety, we ask for
              proof of your identification. We may deny access if we believe
              there is a question about your identity.
            </p>
            <ol style={{ fontWeight: "bold" }} start="21">
              <li>Changes to This Privacy Policy</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              Executive Mafia may modify or change this privacy policy. All
              changes will come into force from the date of posting on the
              Executive Mafia Site. Your continuing usage of the Site after the
              publication by Executive Mafia of an updated version of the
              Privacy Policy implies that you consent to the Privacy Policy, as
              modified. Consequently, it is important that you regularly read
              this Privacy Policy to make sure that you are fully aware of all
              the changes that may have been applied to it.
            </p>
            <ol style={{ fontWeight: "bold" }} start="22">
              <li>Contact Us</li>
            </ol>
            <p style={{ marginLeft: "0.5in" }}>
              If you have any questions about this Privacy Policy, or questions
              about the use of the Site, please contact us by email at the
              following address:{" "}
              <a href="mailto:support@executivemafia.com">
                support@executivemafia.com
              </a>{" "}
              or via mail to:
            </p>
            <p style={{ marginLeft: "0.5in" }}>
              PT. EKSEKUTIF MEDIA UTAMA
              <br />
              21, JALAN MELATI, KOMPLEK CEMARA ASRI
              <br />
              MEDAN CITY, NORTH SUMATRA 20371
              <br />
              INDONESIA
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PrivacyPolicy
